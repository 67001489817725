export const TRANSLATIONS_FR = {
	all_rooms: "Toutes les chambres",
	details: "détails",
	view_Floorplan: "Plan De La Chambre",
	floor_Plan: "Plan d'étage/d'aménagement",
	select_offer_for: "Sélectionner une offre pour la",
	after_3PM: "Après",
	befor_12PM: "Avant",
	room_1: "Chambre",
	total_stay_for: "Séjour total de",
	booking_Total: "Total",
	modify_Reservation: "Modifier la réservation",
	share_Via_Email: "Partager via e-mail",
	send_Email: "Envoyer l'email",
	print: "Imprimer",
	hotel_details: "Détails de l'hôtel",
	name: "Nom",
	address: "Adresse",
	country: "Pays",
	zip: "Postale",
	general_information: "Informations générales",
	booker_name: "Nom d'hôte",
	reservation_details: "Détails de la réservation",
	reservation_number: "Numéro de confirmation",
	crs_confirmation_number: "Numéro de confirmation du SIR",
	status: "Statut de la réservation",
	phone: "Téléphone(de préférence numéro de portable) ",
	rate: "Tarif",
	guest: "Guest",
	dates: "Dates",
	price_breakdown: "Répartition des montants",
	nightly_rate: "Tarif par nuit",
	taxes: "Taxes",
	exclusive_tax: "Taxe exclusive",
	inclusive_tax: "Taxes comprises",
	exclusive_at_hotel: "Exclusif à l'hôtel",
	amend_cancel_policy: "Modifier la politique d'annulation",
	amend_policy: "Politique de modification",
	cancel_policy: "Politique d'annulation",
	modification_charges: "Frais de modification",
	enter_email_id_comma:
		"Saisir les identifiants e-mail en les séparant par des virgules (,)",
	sendEventMailMsg: "L'e-mail de confirmation a été envoyé avec succès",
	validemail: "Veuillez saisir un e-mail valide",
	submit: "Confirmer",
	discover: "Découvrez",
	adults: "Adultes",
	adult: "Adulte",
	children: "Enfants",
	child: "Enfant",
	edit: "Modifier",
	stay_breakdown: "Répartition des séjours",
	nights: "nuits",
	enhancements: "vos améliorations",
	total_enhancement: "Vos améliorations totales",
	add_code: "Ajouter un code",
	add_room: "Ajouter une chambre",
	every_stay_includes: "Inclus dans votre séjour",
	select_room: "CHOISIR UNE CHAMBRE",
	continue_to_book: "Réserver",
	complete_booking: "Réserver maintenant",
	enter_valid_booking_code: "Veuillez saisir un code de réservation valide",
	check_out: "Départ",
	continue: "CONTINUER",
	roomnguest: "CONTINUER",
	roomnguestsmall: "Chambres et clients",
	numberofroom: "Nombre de chambres",
	updatesearch: "Confirmer",
	personal_details: "Informations personnelles",
	confirmation: "Confirmation",
	check_availability: "Vérifier la disponibilité",
	check_availability_text:
		"Pour vérifier la disponibilité de cette chambre, veuillez nous contacter en utilisant les coordonnées ci-dessous",
	discover_suites: "Suite Découverte",
	would_you_like_to_remove:
		"Souhaitez-vous supprimer cette chambre ou souhaitez-vous la modifier ?",
	remove_room: "Supprimer la chambre",
	change_room: "Changer de chambre",
	view_your_stay: "Visualisez votre séjour",
	your_stay: "Votre séjour",
	check_in: "Arrivée",
	select_room: "Sélectionner la chambre",
	filter_rooms: "Filtrer Par",
	sort: "Trier",
	low_to_high: "Prix Croissant",
	high_to_low: "Prix Décroissant",
	view_by: "Afficher Les Résultats Par",
	rooms: "Chambres",
	rates: "Tarifs",
	room_type: "Type de chambre",
	room_type_not_available: "TYPE DE CHAMBRE NON DISPONIBLE",
	features: "Détails",
	room_features_not_available:
		"CARACTÉRISTIQUES DES CHAMBRES NON DISPONIBLES",
	clear: "Annuler",
	apply: "Appliquer",
	hide_comparison: "Cacher la comparaison",
	compare_rooms: "Comparer les chambres",
	average_nightly_rate: "Tarif moyen par nuit",
	view_rooms: "Afficher les chambres",
	hide_rooms: "Cacher les chambres",
	view_less: "Afficher moins",
	iatano: "IATA Code",
	view_more: "En savoir plus",
	bed: "Lit",
	users: "Utilisateurs",
	size: "Taille",
	connecting_room: "Chambre communicante",
	from: "A partir de",
	per_night: "",
	view_room: "Découvrir",
	enquire: "En Savoir Plus",
	add_to_stay: "Ajouter",
	hide_rates: "Cacher les tarifs",
	view_rates: "Afficher les tarifs",
	total_for_1_night: "Total pour 1 nuit",
	total_for_multiple_night: "Total pour [numberofnight] nuits",
	inclusive_of_vat: "TVA comprise",
	view_policies: "Voir les conditions générales",
	policy_loading: "Chargement...",
	policies: "Politiques",
	no_data_header:
		"Il n'y a pas de chambres disponibles pour vos dates sélectionnées",
	no_data_header_2: "Il n'y a pas de chambres disponibles pour vos critères.",
	no_data_detail_1:
		"Veuillez modifier vos dates. Vous pouvez également nous envoyer un courriel à l'adresse suivante",
	no_data_detail_2: "ou appelez-nous au",
	no_data_detail_3: "pour parler à notre équipe de réservation.",
	first_name_required: "Un prenom est obligatoire",
	last_name_required: "Un nom de famille est obligatoire",
	phone_number_required: "Saisissez votre numéro de téléphone",
	email_required: "Saisissez votre adresse e-mail",
	confirm_email_required: "Confirmez votre adresse e-mail",
	country_required: "Sélectionnez votre pays parmi la liste",
	city_required: "Saisissez votre ville",
	zip_code_required: "Saisissez le code postal",
	address_1_required: "Saisissez une adresse",
	card_name_required: "Saisissez le nom du titulaire",
	invalid_card: "Saisissez un numéro de carte de crédit valide",
	card_type_required: "Saisissez un type de carte valide",
	card_number_required: "Saisissez le numéro de la carte de crédit",
	invalid_month_year: "Mois ou année invalide",
	invalid_cvv: "Saisissez un code CVV valide",
	cvv_required: "Saisissez le code de sécurité de la carte de crédit",
	terms_checkbox_required: "Veuillez accepter nos conditions de réservation",
	policy_checkbox_required:
		"Veuillez accepter les termes de notre politique de confidentialité",
	contact_details: "Coordonnées",
	first_name: "Prénom",
	last_name: "Nom",
	phone_number: "Numéro de téléphone",
	email: "Adresse e-mail",
	confirm_email: "Confirmer adresse e-mail",
	opt_in: "Je souhaite m'inscrire et recevoir des offres de la part de l’hôtel [hotelname].",
	The: "Du",
	not_stay_room: " Je ne suis pas la personne qui séjournera à l'hôtel",
	room: "Chambre",
	guest_details: "Coordonnées",
	stay_room: "Utiliser les mêmes coordonnées",
	estimated_time: "Heure d'arrivée prévue*",
	optional_enhancement: "Améliorez votre séjour",
	per_unit: "par unité",
	no_optional_enhancement: "AUCUNE AMÉLIORATION OPTIONNELLE DISPONIBLE",
	additional_requests: "Demandes supplémentaires",
	additional_request_placeholder: "Saisissez toute demande supplémentaire",
	no_data_found: "Aucun résultat trouvé",
	no_address_found:
		"Aucune adresse trouvée, veuillez entrer l'adresse manuellement",
	save_reservation_error: "Sauvegarder l'erreur de réservation",
	billing_details: "Adresse de facturation",
	postal_zip_code: "Code postal",
	lookup: "Recherche",
	look_up: "Recherche",
	choose_address: "Choisir une adresse",
	address_1: "Adresse Ligne 1",
	address_2: "Adresse Ligne 2",
	city: "Ville",
	payment_details: "Modalités de paiement",
	name_on_card: "Nom du titulaire",
	invalid_card_type: "Saisissez un type de carte valide",
	card_number: "Numéro de carte",
	card_type: "Type de carte",
	expiry_date: "MM / AA",
	cvv: "CVV",
	policies: "Conditions générales",
	payment_policy: "Garantie de réservation",
	amendment_policy: "Politique de modification / d'annulation",
	modification_policy: "Politique de modification",
	cancellation_policy: "Politique d'annulation",
	room_name: "Nom de la chambre",
	rate_name: "Taux Nom",
	terms_of_booking_1: "J'ai lu et accepté les",
	terms_of_booking_2: "Modalités de réservation",
	terms_and_conditions: "CONDITIONS GÉNÉRALES",
	privacy_policy_1: "J'accepte les conditions de la",
	privacy_policy_2: "Politique de confidentialité",
	privacy_policy: "POLITIQUE DE CONFIDENTIALITÉ",
	terms_and_conditions_footer: "Conditions Générales",
	privacy_policy_footer: "Politique de confidentialité",
	cookie_policy: "Politique de Cookies",
	copyright_1: "Copyright Maybourne",
	copyright_2: ".Tous droits réservés.",
	after: "Après",
	before: "Avant",
	invalid_email: "L'email n'est pas valide",
	match_email:
		"Votre adresse email et l’adresse de confirmation doivent être les mêmes",
	confirm_email_invalid: "Confirmer l'email est invalide",
	estimated_time_required: "Une estimation de l'heure d'arrivée est requise",
	guest_details_required: "Les détails de l'invité sont requis",
	expiry_date_required: "La date d'expiration est requise",
	invalid_expiry_date: "Date d'expiration invalide",
	valid_expiry_date:
		"La date d'expiration doit être supérieure à aujourd'hui",
	claridges: "Claridge's",
	reservation_number_required: "Le numéro de confirmation est obligatoire",
	your_reservation: "Votre réservation",
	hotel: "Hôtel",
	night: "nuit",
	stay_details: "Détails du Séjour",
	charges: "Charges",
	cancellation_fee: "Frais d'annulation",
	amendment_fee: "Frais de modification",
	cancel_stay: "Annuler le séjour",
	modify_stay: "Modifier le séjour",
	invalid_id_or_email: "ID de réservation ou Email invalide",
	booking_cancelled: "Confirmation d'annulation",
	sorry_to_see_you_go: "Votre réservation a bien été annulée. Nous espèrons vous voir très prochainement à l’hôtel [hotelname].",
	total: "Total",
	return_home: "Accueil",
	free: "Gratuit",
	modify_your_stay: "Modifier votre séjour",
	date_range: "fourchette de dates",
	room_1: "Chambre 1",
	to: "de",
	modify_your_reservation: "Modifier votre réservation",
	enter_reservation_details:
		"Veuillez saisir les détails de votre réservation pour continuer",
	billing_details_required: "L'adresse de facturation est obligatoire",
	payment_privacy_terms_required:
		"Vos détails de paiement sont requis. Veuillez accepter nos conditions de réservation ainsi que les termes de notre politique de confidentialité.",
	payment_terms_required:
		"Les détails de paiement sont requis et veuillez accepter nos conditions de réservation sont requis",
	payment_privacy_required:
		"Les détails de paiement sont requis et veuillez accepter les termes de notre politique de confidentialité",
	payment_details_required: "Les détails du paiement sont requis",
	terms_privacy_required:
		"Veuillez accepter nos conditions de réservation et les conditions de notre politique de confidentialité",
	pm: "PM",
	good_news: "Merci",
	your_booking_confirmed: "Votre réservation est confirmée",
	we_inform_shortly:
		"Nous vous enverrons votre confirmation de réservation par e-mail sous peu",
	view_360_tour: "Voir le tour 360°",
	tour_360: "Tour 360°",
	exclusive_of_vat: "exclusivité de la TVA",
	back: "Retour",
	year: "Année",
	years: "Années",
	error_404: "Erreur 404",
	unable_to_locate_page:
		"Nous sommes désolés, mais nous n'avons pas pu localiser la page <br /> que vous recherchez.",
	if_problem_persists: "Si le problème persiste,",
	email_us: "veuillez nous envoyer un e-mail à",
	call_us: "ou nous appeler au",
	internal_error: "Internal Error",
	something_went_wrong:
		"Something went wrong. Please try again or refresh the page.",
	scheduled_downtime: "Scheduled Downtime",
	we_will_be_back_shortly: "Nous reviendrons bientôt",
	if_you_need_assistance: "Si vous avez besoin d'assistance,",
	or_emailing: "ou par e-mail",
	they_can_assist_you:
		"afin qu'ils puissent vous aider à finaliser votre réservation.",
	we_apologise_for_inconvenience:
		"Nous nous excusons pour la gêne occasionnée.",
	booking_declined: "Réservation refusée",
	booking_declined_msg:
		"Malheureusement, nous n'avons pas pu réserver votre chambre. <br /> Veuillez réessayer ci-dessous",
	book_now: "Réserver",
	your_room: "Votre chambre",
	we_use_cookies:
		"Ce site web utilise des cookies pour améliorer votre expérience de navigation.",
	cookies_link_text: "Charte de confidentialité",
	cookies_link_url:
		"https://www.maybourneriviera.com/fr/politique-de-confidentialite/",
	accept_close: "Accepter",
	under_16: "de moins de 16 ans",
	undergoing_maintenance:
		"Notre système de réservation en ligne est actuellement en maintenance.",
	please_contact_our_team:
		"Veuillez contacter notre équipe en appelant <br/>",
	newsletter_signup: "Inscription à la newsletter",
	stay_overview: "Aperçu du séjour",
	arrival_date: "Date d'arrivée",
	departure_date: "Date de départ",
	total_nights: "Nombre de nuits",
	overall_total: "Total",
	rate_type: "Offre",
	value_added_tax_vat: "Taxe sur la valeur ajoutée (TVA)",
	discretionary_service_charge: "Frais de service discrétionnaires",
	total_room_rate: "Tarif total de la chambre",
	enhancements_str: "Améliorations",
	total_enhancements: "Améliorations totales",
	stay_total: "Séjour total",
	important_information: "Information Importante",
	amendment_policy_str: "Politique de modification",
	rate_description: "Descriptif de l'offre",
	special_code: "Code spécial",
	email_address: "Adresse e-mail",
	reservation_no: "Confirmation Non",
	search_currencies: "Rechercher des devises",
	search_currencies_title: "Rechercher des devises ici",
	suggested_currency: "Devise suggérée",
	all_currency: "Toutes les devises",
	confirmation_number: "numéro de confirmation",
	RATE_DESCRIPTION: "Description du type de chambre",
	IMPORTANT_INFORMATION: "INFORMATIONS IMPORTANTES",
	check_in_time: "Heure d'arrivée",
	check_out_time: "Heure de départ",
	am_check_inout_time: "am",
	pm_check_inout_time: "pm",
	mid_day_check_inout_time: "midi",
	subscribe: "S'abonner",
	inclusive_of_vat_sm: "TVA comprise",
	change: "Modifier",
	remove: "Supprimer",
	per_room: "par chambre",
	per_adult_child: "par adulte et enfant",
	qty: "par unité",
	flat: "quotidien",
	per_person: "par personne",
	per_adult: "par adulte",
	per_child: "par enfant",
	sleeps_upto: "Peut accueillir jusqu'à",
	roomrateincludes: "Taxes et Frais",
	guest_name: "Nom du client",
	grand_total: "Total",
	your_enhancement: "Vos améliorations",
	cap_night: "Nuit",
	booking_total_b: "Réservation Totale",
	noroomsearch: "Aucune chambre trouvée avec les critères de recherche",
	read_more: "En savoir plus",
	valid_estimated_arrival:
		"Veuillez entrer l'heure d'arrivée estimée au format HH:MM (format 24 heures)",
	early_checkin_message:
		"La demande d'enregistrement anticipé n'est pas garantie",
	policy_room: "CHAMBRE",
	signature_suites: "SUITES SIGNATURE",
	title_required: "Le titre est requis",
	country_code_required: "Le code pays est requis",
	country_code_phone_number_required:
		"Le code du pays et le n° de téléphone sont requis",
	taxes_and_fees: "Taxes",
	max_occ_error_phone:
		"Pour [count] ou plus de personnes, veuillez rajouter une chambre ou appeler le",
	max_occ_error_email: " ou nous envoyer un e-mail à l'adresse suivante ",
	remove_info: "Removed upon request",
	avg_price_description: "average per night excl taxes and discretionary",
	mbh_avg_price_helper_text: "average per night excl taxes and discretionary 4% fee",
	mbh_total_price_helper_text: "excl taxes and discretionary 4% fee",
	checkoutNoAvailability:
		"Malheureusement, votre sélection n'est plus disponible. Veuillez choisir parmi les chambres disponibles et les tarifs ci-dessous.",
	no_charges_have_been_incurred:
		"J'ai le plaisir de vous informer qu'aucun frais n'a été engagé pour l'annulation susmentionnée.",
	room_view: "Vue de la chambre",
	sea_view: "Sea View",
	road_view: "Road View",
	pool_view: "Pool View",
	river_view: "River View",
	brook_street: "Brook Street",
	courtyard: "Courtyard",
	brooks_mews_and_davies_street: "Brooks Mews et Davies Street",
	brooks_mews: "Brooks Mews",
	brook_street_and_davies_street: "Brook Street et Davies Street",
	facing_brooks_mews: "Face à Brooks Mews",
	davies_street: "Davies Street",
	brooks_mews_and_london_skyline: "Brooks Mews et London skyline",
	panoramas_of_the_london_skyline: "Panoramas de l'horizon londonien",
	st_pauls_church: "Église St Paul",
	wilton_place: "Wilton Place",
	st_pauls_church: "Église St Paul",
	st_pauls_church_and_the_london_skyline:
		"Église St Paul et l'horizon londonien",
	hyde_park: "Hyde Park",
	adams_row: "Le rang d'Adam",
	carlos_place: "Carlos Place",
	adams_row: "Le rang d'Adam",
	mount_street: "Rue Mount",
	adams_row_and_mayfair_rooftops: "Les toits d'Adam's Row et de Mayfair",
	carlos_place_and_mayfair_rooftops:
		"Les toits de Carlos Place et de Mayfair",
	inner_courtyard: "La cour intérieure",
	hollywood_hills: "Hollywood Hills",
	beverly_canon_gardens: "Beverly Canon Gardens",
	expansive_views_of_riviera_coastline:
		"Vue imprenable sur le littoral de la Côte d'Azur",
	expansive_views_of_the_sea: "Vue imprenable sur la mer",
	sweeping_views_across_the_riviera_coastline:
		"Vue imprenable sur le littoral de la Côte d'Azur",
	spectacular_panoramic_views_out_to_the_riviera_coastline:
		"Vues panoramiques spectaculaires sur le littoral de la Côte d'Azur",
	pan: "Pan",
	spectacular_views_of_the_riviera_coastline:
		"Vue spectaculaire sur le littoral de la Côte d'Azur",
	expansive_views_of_mediterranean_sea:
		"Vue imprenable sur la mer Méditerranée",
	prefix: "Préfixe",
	spectacular_views_of_the_mediterranean_sea:
		"Vues spectaculaires sur la mer Méditerranée",
	spectacular_panoramic_views_of_the_riviera_coastline: "Panoramique Vue Mer",
	expansive_views_of_the_mediterranean_sea:
		"Vue imprenable sur la mer Méditerranée",
	hydepark: "HydePark",
	hyde_park_and_knightsbridge: "Hyde Park et Knightsbridge",
	framed_by_floor_to_ceiling_windows: "framed by floor-to-ceiling windows",
	wilton_place_and_london_skyline: "Wilton Place et le ciel de Londres",
	mount_street_and_mayfair_rooftops: "Toits de Mount Street et de Mayfair",
	amic_views_of_the_riviera_coastline:
		"es vues panoramiques sur le littoral de la Côte d'Azur",
	knightsbridge: "Knightsbridge",
	courtyard_views: "Vue sur la cour intérieure",
	would_you_like_to_change_this_room: "Souhaitez-vous changer de chambre?",
	title: "Civilité*",
	country_code: "Code pays*",
	find_us: "Retrouvez-nous",
	contact_us: "Contact",
	manage_booking: "Gérer la réservation",
	Mr: "M.",
	Mrs: "Mme",
	Miss: "Mlle",
	Ms: "Ms.",
	total_taxes_fees: "Total Taxes & Fees",
	taxes_fees_breakdown: "Taxes & Fees Breakdown",
	inclusive_of_taxes_fees: "Inclusive of taxes and fees",
	blank_comparision_filter: "Veuillez sélectionner une chambre pour comparer",
	languages: "LANGUAGES",
	currency: "DEVISE",
	king_bed: "Lit King Size",
	queen_bed: "Lit grand format",
	double_bed: "Lit double taille",
	single_bed: "Lit unique",
	standard_bed: "Lit standard",
	emperor_bed: "Lit d'empereur",
	people: "personnes",
	avg_size: "Surface d’environ",
	compare_filter: " Veuillez sélectionner une chambre pour comparer",
	compare_room: "Comparez les chambres",
	select_four_rooms: "Sélectionnez jusqu'à 4 chambres",
	ok: "Accueil",
	we_apologise_reservation: "Nous sommes désolés que cette réservation ne puisse plus être annulée en ligne.",
	super_king: "Lit Super King Size",
	cancelled: "Annulé",
	pending: "En attente",
	viewAll: "Voir tout",
	stay_room_checkoutV2: "Appliquer les informations ci-dessus à toutes les pièces",
	confirmed: "Confirmé",
	please_contact_us_by_phone_or_email: "Veuillez nous contacter par téléphone ou par e-mail",
	label_total_nights: "Nombre de nuits",
	per_stay: "par séjour",
	average_per_night: "Tarif moyen par nuit",
	view_rooms_str: "Afficher les chambres",
	view_suits: "Voir Suite",
	check_availability_text_with_suite: "Pour vérifier la disponibilité de cette chambre, veuillez nous contacter en utilisant les coordonnées ci-dessous",
	seasonal_closure: "Le Maybourne Riviera fermera temporairement ses portes du 20 janvier au 19 mai 2025. Nous serons ravis de vous accueillir à nouveau à partir du 20 mai 2025.",
	email_confirmation_helper_text: "C'est à cette adresse e-mail que nous enverrons la confirmation."
};
