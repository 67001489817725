export const TRANSLATIONS_EN_10203099 = {
	all_rooms: "All Suites",
	details: "DETAILS",
	view_Floorplan: "View Floorplan",
	floor_Plan: "FLOORPLAN",
	select_offer_for: "Select Offer for",
	total_stay_for: "Total stay for",
	booking_Total: "Total",
	modify_Reservation: "Modify Reservation",
	share_Via_Email: "Share Via Email",
	send_Email: "Send Email",
	print: "Print",
	hotel_details: "Hotel Details",
	name: "Name",
	address: "Address",
	country: "Country",
	zip: "Zip",
	general_information: "General Information",
	booker_name: "Booker Name",
	reservation_details: "Reservation Details",
	reservation_number: "Confirmation Number",
	crs_confirmation_number: "CRS Confirmation No.",
	status: "Status",
	phone: "Phone",
	rate: "Rate",
	guest: "Guest",
	dates: "Dates",
	price_breakdown: "Price Breakdown",
	nightly_rate: "Nightly Rate",
	taxes: "Taxes",
	exclusive_tax: "Exclusive Tax",
	inclusive_tax: "Inclusive Tax",
	exclusive_at_hotel: "Exclusive at Hotel",
	amend_cancel_policy: "Amend Cancel Policy",
	amend_policy: "Amend Policy",
	cancel_policy: "Cancel Policy",
	modification_charges: "Modification Charges",
	enter_email_id_comma: "Enter email(s), separate multiple with comma(,)",
	sendEventMailMsg: "Confirmation email has been sent successfully.",
	validemail: "Please enter valid email.",
	submit: "Continue",
	discover: "Discover",
	adults: "Adults",
	adult: "Adult",
	children: "Children",
	child: "Child",
	edit: "Edit",
	stay_breakdown: "Stay breakdown",
	nights: "nights",
	enhancements: "Your enhancements",
	total_enhancement: "Your enhancements total",
	add_code: "Add Code",
	add_room: "Add Suite",
	every_stay_includes: "Every Stay Includes",
	continue_to_book: "CONTINUE TO BOOK",
	complete_booking: "COMPLETE BOOKING",
	enter_valid_booking_code:
		"You have entered an invalid special code. Please amend your entry and continue.",
	check_out: "Check-Out",
	continue: "CONTINUE",
	roomnguest: "SELECT SUITES & GUESTS",
	roomnguestsmall: "Suites & Guests",
	numberofroom: "Number of Suites",
	updatesearch: "Update Search",
	personal_details: "Personal Details",
	confirmation: "Confirmation",
	check_availability: "Check Availability",
	check_availability_text:
		"To check availability for this suite, please contact us using the details below",
	discover_suites: "Discover Suite",
	would_you_like_to_remove:
		"Would you like to remove this suite or would you like to change this suite?",
	would_you_like_to_change_this_room: "Would you like to change this suite?",
	remove_room: "Remove Suite",
	change_room: "Change Suite",
	view_your_stay: "Your Stay Summary",
	your_stay: "Your Stay",
	check_in: "Check-In",
	select_room: "Select Suite",
	filter_rooms: "Filter Suites",
	sort: "Sort",
	low_to_high: "Low to High",
	high_to_low: "High to Low",
	view_by: "View By",
	rooms: "Suites",
	rates: "Rates",
	room_type: "Suite Type",
	room_type_not_available: "SUITE TYPE NOT AVAILABLE",
	features: "Features",
	room_features_not_available: "SUITE FEATURES NOT AVAILABLE",
	clear: "Clear",
	apply: "Apply",
	hide_comparison: "Hide Comparison",
	compare_rooms: "Compare Suites",
	average_nightly_rate: "Average nightly rate",
	view_rooms: "View Suites",
	hide_rooms: "Hide Suites",
	view_less: "View Less",
	view_more: "View More",
	bed: "Bed",
	users: "Users",
	size: "Size",
	connecting_room: "Connecting Suite",
	from: "From",
	per_night: "",
	view_room: "View Suite",
	enquire: "Enquire",
	add_to_stay: "Add to Stay",
	hide_rates: "Hide Rates",
	view_rates: "View Rates",
	total_for_1_night: "Total for [numberofnight] night",
	total_for_multiple_night: "Total for [numberofnight] nights",
	inclusive_of_vat: "Inclusive of VAT",
	exclusive_of_vat: "exclusive of taxes and fees",
	view_policies: "View Policies",
	policy_loading: "Loading...",
	policies: "Policies",
	no_data_header:
		"Unfortunately there is no availability for your selection.",
	no_data_header_2:
		"Unfortunately there is no availability for your selection.",
	no_data_detail_1:
		"Please amend your search criteria. Alternatively, please email us at",
	no_data_detail_2: "or call us on",
	no_data_detail_3: "to speak to our Reservations Team.",
	first_name_required: "First Name is required",
	last_name_required: "Last Name is required",
	phone_number_required: "Phone Number is required",
	email_required: "Email address is required",
	confirm_email_required: "Confirm email address is required",
	country_required: "Country is required",
	city_required: "City is required",
	zip_code_required: "Zip Code is required",
	address_1_required: "Address is required",
	card_name_required: "Name on Card is required",
	invalid_card: "Invalid Card Number",
	card_type_required: "Card Type is required",
	invalid_card_type: "Invalid card type",
	card_number_required: "Card Number is required",
	invalid_month_year: "Invalid month or year",
	invalid_cvv: "Invalid CVC",
	cvv_required: "CVC is required",
	terms_checkbox_required: "Please accept our Terms and Conditions",
	policy_checkbox_required: "Please accept the terms of our Privacy Policy",
	contact_details: "Contact Details",
	first_name: "First Name",
	last_name: "Last Name",
	phone_number: "Phone Number",
	invalid_phone: "Invalid Phone Number",
	email: "Email address",
	prefix: "Prefix",
	confirm_email: "Confirm email address",
	opt_in: "I would like to sign up to receive news and updates from [hotelname].",
	not_stay_room: "I am not staying in any suite",
	room: "Suite",
	guest_details: "Guest Details",
	stay_room: "Same as contact details",
	stay_room_checkoutV2: "Apply the above information to all rooms",
	estimated_time: "Estimated time of arrival (24 Hour format)",
	optional_enhancement: "Enhance your stay",
	per_unit: "per unit",
	no_optional_enhancement: "NO OPTIONAL ENHANCEMENTS AVAILABLE",
	additional_requests: "Additional Requests",
	additional_request_placeholder: "Special occasion? Please let us know any additional requests here...",
	no_data_found: "No Data Found",
	no_address_found: "No address found, please enter address manually",
	save_reservation_error: "Save reservation error",
	billing_details: "Billing Address",
	postal_zip_code: "Postal / Zip Code",
	lookup: "Lookup",
	look_up: "Look Up",
	choose_address: "Choose Address",
	address_1: "Address Line 1",
	address_2: "Address Line 2",
	city: "City",
	payment_details: "Payment Details",
	name_on_card: "Name on Card",
	card_number: "Card Number",
	card_type: "Card Type",
	expiry_date: "MM / YY",
	cvv: "CVC",
	policies: "Policies",
	payment_policy: "Guarantee Policy",
	amendment_policy: "Amendment / Cancellation Policy",
	modification_policy: "Amendment Policy",
	cancellation_policy: "Cancellation Policy",
	room_name: "Suite Name",
	rate_name: "Rate Name",
	terms_of_booking_1: "I have read and agreed to the",
	terms_of_booking_2: "Terms and Conditions",
	terms_and_conditions: "TERMS AND CONDITIONS",
	privacy_policy_1: "I accept the terms of the",
	privacy_policy_2: "Privacy Policy",
	privacy_policy: "PRIVACY POLICY",
	terms_and_conditions_footer: "Terms & Conditions",
	privacy_policy_footer: "Privacy Policy",
	cookie_policy: "Cookie Policy",
	copyright_1: "Copyright Maybourne",
	copyright_2: ". All rights reserved.",
	after: "After",
	before: "Before",
	invalid_email: "Email address is invalid",
	match_email: "Email addresses must match",
	confirm_email_invalid: "Confirm Email address is invalid",
	estimated_time_required: "Estimated time of arrival is required",
	guest_details_required: "Guest Details are required",
	expiry_date_required: "Expiry Date is required",
	invalid_expiry_date: "Invalid Expiry Date",
	valid_expiry_date: "Expiry Date must be greater than today",
	claridges: "Claridge's",
	reservation_number_required: "Confirmation Number is required",
	your_reservation: "Your Reservation",
	hotel: "Hotel",
	night: "night",
	stay_details: "Stay Details",
	charges: "Charges",
	cancellation_fee: "Cancellation Fee",
	amendment_fee: "Amendment Fee",
	cancel_stay: "Cancel Stay",
	modify_stay: "Modify Stay",
	invalid_id_or_email: "Reservation ID or Email invalid",
	booking_cancelled: "YOUR RESERVATION HAS BEEN CANCELLED",
	sorry_to_see_you_go: "We are sorry that you will not be staying with us on this occasion and look forward to welcoming you in the future.​",
	total: "Total",
	return_home: "Return Home",
	free: "Does not apply",
	modify_your_stay: "Modify Your Stay",
	date_range: "date range",
	room_1: "Suite 1",
	to: "to",
	modify_your_reservation: "Modify Your Reservation",
	enter_reservation_details:
		"Please enter your reservation details to continue",
	billing_details_required: "Billing Address is required",
	payment_privacy_terms_required:
		"Payment Details are required, Please accept our Terms and Conditions and the terms of our Privacy Policy",
	payment_terms_required:
		"Payment Details are required, Please accept our Terms and Conditions",
	payment_privacy_required:
		"Payment Details are required, Please accept the terms of our Privacy Policy",
	payment_details_required: "Payment Details are required",
	terms_privacy_required:
		"Please accept our Terms and Conditions and the terms of our Privacy Policy",
	pm: "PM",
	good_news: "THANK YOU",
	your_booking_confirmed: "Your booking is confirmed",
	we_inform_shortly: "We will email your booking confirmation shortly",
	view_360_tour: "View 360 Tour",
	tour_360: "360 Tour",
	back: "Back",
	year: "Year",
	years: "Years",
	error_404: "Error 404",
	unable_to_locate_page:
		"We're sorry, we're unable to locate the page you <br /> are looking for.",
	if_problem_persists: "If the problem persists,",
	email_us: "please email us at ",
	call_us: "or call us on ",
	internal_error: " SORRY, SOMETHING WENT WRONG",
	remove_info: "Removed upon request",
	something_went_wrong: "Please refresh the page or try again later.",
	avg_price_description: "average per night excl taxes and discretionary",
	total_price_description: "excl taxes and discretionary",
	fee: "fee",
	scheduled_downtime: "Scheduled Downtime",
	we_will_be_back_shortly: "We'll be back shortly",
	if_you_need_assistance: "If you need assistance,",
	we_use_cookies:
		"This website uses cookies to ensure you get the best experience on our website.",
	cookies_link_text: "Privacy Policy",
	cookies_link_url: "https://www.maybourneriviera.com/privacy-policy/",
	accept_close: "Accept & Close",
	under_16: "Under 16",
	undergoing_maintenance:
		"Our online booking system is currently undergoing maintenance.",
	please_contact_our_team: "Please contact our team by calling <br/>",
	or_emailing: "or emailing",
	they_can_assist_you: "so they can assist you with finalising your booking.",
	we_apologise_for_inconvenience:
		"We apologise for any inconvenience caused.",
	booking_declined: "Booking Declined",
	booking_declined_msg:
		"Unfortunately we couldn't process your booking. <br /> Please try again below",
	book_now: "Book now",
	your_room: "Your Suite",
	newsletter_signup: "Newsletter Sign Up",
	stay_overview: "Stay Overview",
	arrival_date: "Arrival Date",
	departure_date: "Departure Date",
	total_nights: "Total Nights",
	overall_total: "Overall Total",
	confirmation_number: "Confirmation No.",
	rate_type: "Rate Type",
	value_added_tax_vat: "Value Added Tax (VAT)",
	discretionary_service_charge: "Discretionary Service Charge",
	total_room_rate: "Total Suite Rate",
	enhancements_str: "Enhancements",
	total_enhancements: "Total Enhancements",
	stay_total: "Stay Total",
	important_information: "important information",
	amendment_policy_str: "Amendment policy",
	rate_description: "Rate Description",
	special_code: "Special Code",
	email_address: "Email Address",
	reservation_no: "Confirmation No.",
	search_currencies: "Search Currency",
	search_currencies_title: "Search Currency Here",
	suggested_currency: "Suggested Currency",
	all_currency: "All Currency",
	subscribe: "Subscribe",
	inclusive_of_vat_sm: "inclusive of VAT",
	change: "Change",
	remove: "Remove",
	per_room: "per suite",
	per_adult_child: "per adult and children",
	qty: "per unit",
	flat: "daily",
	once: "once",
	per_person: "per person",
	per_adult: "per adult",
	per_child: "per children",
	check_in_time: "Check-In Time",
	check_out_time: "Check-Out Time",
	am_check_inout_time: "am",
	pm_check_inout_time: "pm",
	mid_day_check_inout_time: "midday",
	sleeps_upto: "Sleeps up to",
	roomrateincludes: "Taxes & Fees",
	guest_name: "Guest Name",
	grand_total: "Grand Total",
	your_enhancement: "Your Enhancements",
	cap_night: "Night",
	booking_total_b: "Booking Total",
	noroomsearch: "No suite found with search criteria",
	read_more: "Read more",
	modify_dates: "Modify Dates",
	valid_estimated_arrival:
		"Please enter estimated time of arrival in HH:MM (24 Hour format)",
	country_code: "Country Code*",
	title: "Title*",
	early_checkin_message: "Early check-in request is not guaranteed",
	policy_room: "SUITE",
	signature_suites: "SIGNATURE SUITES",
	title_required: "Please select title",
	country_code_required: "Please select Country Code",
	country_code_phone_number_required:
		"Country code and Phone number is required",
	taxes_and_fees: "Taxes and Fees",
	max_occ_error_phone:
		"For [count] or more people, please add a room or call ",
	max_occ_error_email: " or email us at ",
	checkoutNoAvailability:
		"Unfortunately, your selection is no longer available. Please choose from the available suites and rates below.",
	no_charges_have_been_incurred:
		"I am pleased to advise you that no charges have been incurred on the above cancellation.",
	room_view: "Suite View",
	iatano: "IATA Code",
	sea_view: "Sea View",
	road_view: "Road View",
	pool_view: "Pool View",
	river_view: "River View",
	brook_street: "Brook Street",
	courtyard: "Courtyard",
	brooks_mews_and_davies_street: "Brooks Mews and Davies Street",
	brooks_mews: "Brooks Mews",
	brook_street_and_davies_street: "Brook Street and Davies Street",
	facing_brooks_mews: "Facing Brooks Mews",
	davies_street: "Davies Street",
	brooks_mews_and_london_skyline: "Brooks Mews and London skyline",
	panoramas_of_the_london_skyline: "Panoramas of the London skyline",
	st_pauls_church: "St Paul's Church",
	wilton_place: "Wilton Place",
	st_pauls_church: "St Paul’s Church",
	st_pauls_church_and_the_london_skyline:
		"St Paul’s Church and the London skyline",
	hyde_park: "Hyde Park",
	adams_row: "Adam's Row",
	carlos_place: "Carlos Place",
	adams_row: "Adam’s Row",
	mount_street: "Mount Street",
	adams_row_and_mayfair_rooftops: "Adam's Row and Mayfair rooftops",
	carlos_place_and_mayfair_rooftops: "Carlos Place and Mayfair rooftops",
	inner_courtyard: "Inner courtyard",
	hollywood_hills: "Hollywood Hills",
	beverly_canon_gardens: "Beverly Canon Gardens",
	expansive_views_of_riviera_coastline:
		"Expansive views of Riviera coastline",
	expansive_views_of_the_sea: "Expansive views of the sea",
	sweeping_views_across_the_riviera_coastline:
		"Sweeping views across the Riviera coastline",
	spectacular_panoramic_views_out_to_the_riviera_coastline:
		"Spectacular panoramic views out to the Riviera coastline",
	pan: "Pan",
	spectacular_views_of_the_riviera_coastline:
		"Spectacular views of the Riviera coastline",
	expansive_views_of_mediterranean_sea:
		"Expansive views of Mediterranean sea",
	spectacular_views_of_the_mediterranean_sea:
		"Spectacular views of the Mediterranean sea",
	spectacular_panoramic_views_of_the_riviera_coastline: "Panoramic Sea Views",
	expansive_views_of_the_mediterranean_sea:
		"Expansive views of the Mediterranean sea",
	hydepark: "HydePark",
	hyde_park_and_knightsbridge: "Hyde Park and Knightsbridge",
	framed_by_floor_to_ceiling_windows: "framed by floor-to-ceiling windows",
	wilton_place_and_london_skyline: "Wilton Place and London skyline",
	mount_street_and_mayfair_rooftops: "Mount Street and Mayfair rooftops",
	amic_views_of_the_riviera_coastline: "amic views of the Riviera coastline",
	knightsbridge: "Knightsbridge",
	courtyard_views: "Courtyard views",
	find_us: "Find Us",
	contact_us: "Contact Us",
	manage_booking: "Manage Booking",
	Ms: "Ms",
	Mr: "Mr",
	Mrs: "Mrs",
	Miss: "Miss",
	Mx: "Mx",
	Dr: "Dr",
	Sir: "Sir",
	Lady: "Lady",
	Prof: "Prof",
	view: "View",
	hide: "Hide",
	room_total: "Suite Total",
	total_taxes_fees: "Total Taxes & Fees",
	taxes_fees_breakdown: "Taxes & Fees Breakdown",
	inclusive_of_taxes_fees: "Inclusive of taxes and fees",
	languages: "LANGUAGES",
	currency: "Currency",
	king_bed: " King Bed",
	queen_bed: "Queen bed",
	double_bed: "Double bed",
	single_bed: "Single bed",
	standard_bed: "Standard bed",
	emperor_bed: "Emperor bed",
	people: "people",
	avg_size: "Average Size",
	compare_filter: "Please select suite to compare",
	compare_room: "Compare Suites",
	select_four_rooms: "Select up to 4 suites",
	exclusive_of_taxes_and_fees: "exclusive of taxes and fees",
	excl_taxes_and_fees: "excl. taxes and fees",
	ok: "OK",
	we_apologise_reservation: "We apologize that this reservation can no longer be cancelled online.",
	super_king: "Super King Bed",
	cancelled: "Cancelled",
	pending: "Pending",
	confirmed: "Confirmed",
	please_contact_us_by_phone_or_email: "Please contact us by phone or e-mail",
	label_total_nights: "Total Nights",
	per_stay: "per stay",
	average_per_night: "average per night",
	your_emory_assistance: "Our team will be in contact to ensure your seamless arrival at The Emory and that all the finer details are handled with the utmost care",
	view_rooms_str: "View Rooms",
	view_suits: "View Suites",
	check_availability_text_with_suite: "To check availability for this suite, please contact us using the details below",
	viewAll: "View All",
	email_confirmation_helper_text: "This is the email address we will send the confirmation to",
};
