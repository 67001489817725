import React, { useContext, useMemo } from 'react'
import { useTranslation } from "react-i18next"
import Moment from "moment";
import { LocalStorage, SessionStorage } from '../../../utils/storage'
import { KEYS, SITE_ID_LIST } from '../../../utils/constant'
import { SITE_ID_LIST_HASH } from '../../../utils/utils'
import getSymbolFromCurrency from "currency-symbol-map";
import { currencyDisplay } from "../../../utils/utils";
import qs from 'qs';
import AppContext from "../../../utils/appContext";

const NoDataFound = ({
	availabilityData,
	hotelData,
	siteId
}) => {
	const { t } = useTranslation()
	const context = useContext(AppContext);
	const queryData = {
		checkin: SessionStorage.get(KEYS.CHECK_IN_DATE),
		checkout: SessionStorage.get(KEYS.CHECKOUT_DATE),
		//lang: LocalStorage.get(KEYS.LANG),
	}

	let totalNights = Moment(queryData?.checkout, "YYYY-MM-DD")?.diff(Moment(queryData?.checkin, "YYYY-MM-DD"), "days");

	const goToDeepLink = (siteUrl, itemHotelId) => {
		const bookingCode = SessionStorage.get(KEYS.bookingcode)
		const roomData = SessionStorage.getJSON(KEYS.ROOM_DATA)

		if (bookingCode) {
			queryData.bookingcode = bookingCode
		}

		if (roomData?.length) {
			queryData.room = roomData?.length
			roomData.forEach((x, index) => {
				queryData[`adult${index + 1}`] = x?.adults || 1;
				queryData[`child${index + 1}`] = x?.childrens?.length || 0;
			});
		}
		const query = qs.stringify(queryData);
		const deepLinkUrl = `https://${siteUrl}/index/${SessionStorage.get(KEYS.HOTEL_ID)}?${query}`
		//const deepLinkUrl = `https://${siteUrl}/`
		window.open(deepLinkUrl, "_blank")
	}

	const crossSiteListSorted = useMemo(() => {
		if (siteId == SITE_ID_LIST_HASH.claridges) return [{ ...availabilityData?.find(({ siteID }) => siteID === SITE_ID_LIST_HASH.connaught) }, { ...availabilityData?.find(({ siteID }) => SITE_ID_LIST_HASH.emory?.includes(siteID)) }, { ...availabilityData?.find(({ siteID }) => siteID === SITE_ID_LIST_HASH.berkley) }]
		if (siteId == SITE_ID_LIST_HASH.connaught) return [{ ...availabilityData?.find(({ siteID }) => siteID === SITE_ID_LIST_HASH.claridges) }, { ...availabilityData?.find(({ siteID }) => SITE_ID_LIST_HASH.emory?.includes(siteID)) }, { ...availabilityData?.find(({ siteID }) => siteID === SITE_ID_LIST_HASH.berkley) }]
		if (SITE_ID_LIST_HASH.emory?.includes(siteId.toString())) return [{ ...availabilityData?.find(({ siteID }) => siteID === SITE_ID_LIST_HASH.berkley) }, { ...availabilityData?.find(({ siteID }) => siteID === SITE_ID_LIST_HASH.claridges) }, { ...availabilityData?.find(({ siteID }) => siteID === SITE_ID_LIST_HASH.connaught) }]
		if (siteId == SITE_ID_LIST_HASH.berkley) return [{ ...availabilityData?.find(({ siteID }) => SITE_ID_LIST_HASH.emory?.includes(siteID)) }, { ...availabilityData?.find(({ siteID }) => siteID === SITE_ID_LIST_HASH.claridges) }, { ...availabilityData?.find(({ siteID }) => siteID === SITE_ID_LIST_HASH.connaught) }]
	}, [availabilityData, siteId])

	return (
		<>
			<div className='pageTitleWrap2 paddingB30 marginTB30 noDataFoundInfo'>
				<h1 className='m0 paddingB10'>{t("no_data_header")}</h1>
				{SITE_ID_LIST_HASH?.mbr?.includes(siteId?.toString()) && new Date(queryData?.checkin) < new Date(2025, 4, 16, 0, 0, 0) && new Date() <= new Date(2025, 4, 15, 23, 59, 59) && <p>{t("seasonal_closure")}</p>}
				<p>
					{t("no_data_detail_1")}{" "}
					<a href={`mailto:${hotelData?.email}`}>{hotelData?.email}</a>{" "}
					{t("no_data_detail_2")}{" "}
					<a href={`tel:${hotelData?.phone}`}>{hotelData?.phone}</a>{" "}
					{t("no_data_detail_3")}
				</p>
				<p className='modifyDatesMobileOnly'>
					<button
						className='customBtn customBtnDark'
						onClick={() => {
							context.setYourStay(true);
						}}
					>
						{t("modify_dates")}
					</button>
				</p>
			</div>
			<div className='roomsList noDataMinHeight'>
				{crossSiteListSorted?.filter((data) => Object.keys(data).length > 0)?.map((item) => {
					var btnText = 'view_rooms_str';
					if (Number(item['siteID']) === SITE_ID_LIST[5] || Number(item['siteID']) === SITE_ID_LIST[8]) {
						btnText = 'view_suits'
					}

					return (
						<>
							<div className='columnWrap singleRoomsList'>
								<div className='column50 posRelative'>
									<div className='roomSimpleImages'>
										<img
											src={item.full_image}
											alt={item.hotel_name}
										/>
									</div>
								</div>
								<div className='column50'>
									<div className='roomListDetails'>
										<h2 className='customTitle' onClick={() => goToDeepLink(item.site_url)}>
											{item.hotel_name}
										</h2>
										{item?.minimum_price ? (
											<span className='bookingFromPrice'>
												{t("from")}{" "}
												{LocalStorage.get(
													KEYS.SELECTED_CURRENCY
												) &&
													getSymbolFromCurrency(
														LocalStorage.get(
															KEYS.SELECTED_CURRENCY
														)
													)}
												{currencyDisplay(item?.minimum_price / totalNights)} (
												{t("average_per_night")})
											</span>
										) : (
											""
										)}
										<div className='roomShortDesc paddingTB15'>
											<div
												dangerouslySetInnerHTML={{
													__html:
														item?.site_short_description ||
														item?.site_long_description ||
														"",
												}}
											/>
										</div>
										<div className='buttonsGroup'>
											{item?.site_url && (
												<a
													className='customBtn customBtnDark'
													href='javascript:void(0);'
													onClick={() =>
														goToDeepLink(item.site_url)
													}
												>
													{t(btnText)}
												</a>
											)}
										</div>
									</div>
								</div>
							</div>
						</>)
				})}
			</div>
		</>
	);
}

export default NoDataFound