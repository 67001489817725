import React, { useContext, useState, useEffect } from "react";
import { IconGroup } from "../common/Buttons";
import { KEYS } from "../../utils/constant";
import { SessionStorage } from "../../utils/storage";
import { useTranslation } from "react-i18next";
import { route } from "../../utils/route";
import { useNavigate } from "react-router-dom";
import AppContext from "../../utils/appContext";
import { enableScroll, getMaxCount } from "../../utils/utils";
import MaxOccupancyError from "../common/Modal/maxOccupancyError";
import { useLocation } from "react-router-dom";
import { notShowCrossSellSiteId } from "../../utils/utils"

const RoomSelection = props => {
  const { t } = useTranslation();
  const location = useLocation()
  const navigate = useNavigate();
  const context = useContext(AppContext);
  let cartItems = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));
  let cartView = [];
  let tempcart = [];
  const [roomData, setroomData] = useState(cartItems);
  const [tempRoomData, settempRoomData] = useState(tempcart);
  const [diplayMaxOccError, setdisplayMaxOccError] = useState(0);
  const [changedAdultChild, setchangedAdultChild] = useState(0);

  cartItems.length > 0 &&
    cartItems.map((item, index) => {
      cartView[index] = item;
      tempcart[index] = {
        room: item.room,
        adults: item.adults,
        childrens: item.childrens.length
      };
    });

  let openRoomEdit = roomno => {
    enableScroll()
    props.openRoomEdit(roomno);
  };

  const addRooms = () => {
    let newroomcount = roomData.length + 1;
    let newRoom = {
      room: newroomcount,
      adults: 1,
      childrens: [],
      price: [],
      room_info: { room_id: 0, rate_id: 0, room_name: "", rate_name: "" }
    };
    let setuproom = roomData;
    setuproom.push(newRoom);
    setroomData(setuproom);
  };

  const removeRooms = () => {
    let setuproomd = roomData;
    setuproomd.pop();
    setroomData(setuproomd);
  };

  const goToLoadRoomList = async () => {
    sessionStorage.setItem("checkInDate", props.checkindate);
    sessionStorage.setItem("checkoutDate", props.checkoutdate);
    enableScroll()
    context.setYourStay(false);
    if (props.showRoom) {
      roomData.length > 0 && roomData.map((item, index) => {
        tempRoomData.map(function (element, selectedroomindex) {
          if (element.room == item.room) {
            roomData[index].adults = element.adults;
            if (element.childrens == 0) {
              roomData[index].childrens = [];
            } else {
              roomData[index].childrens = [
                ...Array(element.childrens).keys()
              ];
            }
            roomData[index].price = [];
            roomData[index].room_info = {
              room_id: 0,
              rate_id: 0,
              room_name: "",
              rate_name: ""
            };
            roomData[index].enhancement = [];
          }
        });
      });
      SessionStorage.setJSON(KEYS.ROOM_DATA, roomData);
    } else {
      cartItems.length > 0 && cartItems.map((item, index) => {
        tempRoomData.map(function (element, selectedroomindex) {
          if (element.room == item.room && props.roomnumber == item.room) {
            cartView[index].adults = element.adults;
            if (element.childrens == 0) {
              cartView[index].childrens = [];
            } else {
              cartView[index].childrens = [
                ...Array(element.childrens).keys()
              ];
            }
            cartView[index].price = [];
            cartView[index].room_info = {
              room_id: 0,
              rate_id: 0,
              room_name: "",
              rate_name: ""
            };
            roomData[index].enhancement = [];
          }
        });
        SessionStorage.setJSON(KEYS.ROOM_DATA, cartView);
      });
    }
    await props.getRoomList();
    await props.getRoomList("", true);
    await props.openRoomEdit();

    if (props.availability === 1 && context.authToken && !notShowCrossSellSiteId.includes(context?.configData?.site_id) && (location.pathname === route.roomList || location.pathname.split("/")[1] === "index")) {
      await props.getNoAvailability();
    }

    window.scrollTo(0, 0)
    navigate(route.roomList)
  };

  // useEffect(() => {
  //   let seterror = 0;
  //   if (tempRoomData && tempRoomData.length > 0) {
  //     tempRoomData.map(function(element, index) {
  //       if((element.adults + element.childrens) > 2){
  //         setdisplayMaxOccError(1)
  //         seterror = 1;
  //       }
  //     })
  //   }
  //   if(seterror === 0){
  //     setdisplayMaxOccError(0)
  //   }

  // }, [changedAdultChild])
  useEffect(() => {
    if (tempRoomData?.length) {
      let roomDataLength = roomData?.length
      let remainingData = tempRoomData?.slice(0, roomDataLength)
      const hasMaxOccError = remainingData?.some(element => (element.adults + element.childrens) > props.siteConfig?.max_occupancy);
      setdisplayMaxOccError(hasMaxOccError ? 1 : 0);
    }
  }, [changedAdultChild, props.siteConfig, roomData?.length, tempRoomData]);

  return (
    <div class="cartSidebar">
      <div class="cartRoomEditSidebar posRelative">
        <button
          type="button"
          class="closeSideBarPopup closePopup closeIcon posAbsolute"
          onClick={() => openRoomEdit(props.roomnumber)}
          aria-label="Close Button"
        ></button>
        <h2 class="m0 paddingTB25 textUppercase">{t("roomnguestsmall")}</h2>
        {props.showRoom ? (
          <div class="columnWrap alignItemsCenter editRoomCountsSidebar paddingB20">
            <div class="column35 pL0">
              <label for="roomundefined">{t("numberofroom")}</label>
            </div>
            <div class="column65 pR0">
              <IconGroup
                defaultValue={roomData.length}
                type="room"
                minValue="1"
                maxValue={props.siteConfig.allow_no_rooms}
                addRooms={addRooms}
                removeRooms={removeRooms}
                isDisabled={SessionStorage.getJSON(KEYS.IS_RESERVATION) && SessionStorage.get("is_modification")}
              />
            </div>
          </div>
        ) : ""}
        <div class="editRoomWrapGroup">
          {roomData.length > 0 && roomData.map((item, index) => {
            let roomNo = index + 1;
            if ((props.showsingleroom === true && props.roomnumber === roomNo) || props.showsingleroom === false) {
              return (
                <div class="editRoomWrap paddingTB20">
                  <h2 class="m0 paddingB15 textUppercase">{t("room")} {roomNo}</h2>
                  <div class="columnWrap alignItemsCenter">
                    <div class="column35 pL0"><label for={`adult${roomNo}`}>{t("adults")}</label></div>
                    <div class="column65 pR0">
                      <IconGroup
                        defaultValue={item.adults}
                        type="adult"
                        minValue="1"
                        maxValue={props.siteConfig.max_adult}
                        max_occupancy={props.siteConfig.max_occupancy}
                        roomNumber={roomNo}
                        settempRoomData={settempRoomData}
                        tempRoomData={tempRoomData}
                        setchangedAdultChild={setchangedAdultChild}
                        changedAdultChild={changedAdultChild}
                      />
                    </div>
                  </div>
                  <div class="columnWrap alignItemsCenter">
                    <div class="column35 pL0"><label for={`children${roomNo}`}>{t('children')}{" "}({t('under_16')})</label></div>
                    <div class="column65 pR0">
                      <IconGroup
                        defaultValue={item.childrens.length}
                        type="children"
                        minValue="0"
                        maxValue={props.siteConfig.max_child}
                        max_occupancy={props.siteConfig.max_occupancy}
                        roomNumber={roomNo}
                        settempRoomData={settempRoomData}
                        tempRoomData={tempRoomData}
                        setchangedAdultChild={setchangedAdultChild}
                        changedAdultChild={changedAdultChild}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}
          <MaxOccupancyError diplayMaxOccError={diplayMaxOccError} />
        </div>

        <div class="cartSidebarBtn paddingT20">
          <button
            className={`customBtn customBtnDark w100 ${diplayMaxOccError === 1 ? "btnDisable" : ""}`}
            onClick={() => diplayMaxOccError === 1 ? false : goToLoadRoomList()}
            disabled={diplayMaxOccError === 1 ? true : false}
          >
            {t("updatesearch")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoomSelection;
